import { StarIcon } from "@radix-ui/react-icons";

const StarRating: React.FC<{
    rating: number;
    setRating: (rating: number) => void;
}> = ({ rating, setRating }) => {
    return (
        <div className="flex space-x-1 justify-center xl:justify-start">
            {[1, 2, 3, 4, 5].map((star) => (
                <button
                    key={star}
                    onClick={() => setRating(star)}
                    className="focus:outline-none"
                >
                    <StarIcon
                        className={`w-6 h-6 ${
                            star <= rating
                                ? "text-yellow-400"
                                : "text-[#828282]"
                        }`}
                    />
                </button>
            ))}
        </div>
    );
};

export default StarRating;
