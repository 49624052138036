import React, { useEffect, useState } from "react";
import BackgroundImage from "./components/common/BackgroundImage";
import InitialButtons from "./components/common/InitialButtons";
import ChatWindow from "./components/chat/ChatWindow";
import { useImageGallery } from "./hooks/useImageGallery";
import Feedback from "./components/common/Feedback";
import { uuid, validateUuid } from "./utils/utils";
import "./styles/App.css";

const initClient = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientFromUrl = urlParams.get("client_id");
    const clientFromLocal = localStorage.getItem("client_id");

    try {
        const o_client_id = urlParams.get("o_client_id")
        
        if (o_client_id) {
            document.cookie = `o_client_id=${encodeURIComponent(o_client_id)}`;
            window.location.search = ""
        }

        if (urlParams.get("reset") != null) {
            window.location.search = "";
            throw new Error("No client id found");
        }
        if (clientFromUrl != null && validateUuid(clientFromUrl)) {
            return clientFromUrl;
        }

        if (clientFromLocal != null && validateUuid(clientFromLocal)) {
            localStorage.setItem("client_id", clientFromLocal);
            return clientFromLocal;
        }

        throw new Error("No client id found");
    } catch (e) {
        const newId = uuid();
        console.log("No client_id found, use new generated id:", newId);
        localStorage.setItem("client_id", newId);
        return newId;
    }
};

const App: React.FC = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [initialMessage, setInitialMessage] = useState("");
    const [clientId] = useState(initClient());
    const [streaming, setStreaming] = useState(false);
    const [hotelsReady, setHotelsReady] = useState(false);
    const { images, activeItem } = useImageGallery(clientId, streaming);
    const [started, setStarted] = useState(false);
    const [desktopRes, setDesktopRes] = useState(
        window.matchMedia("(min-width: 1280px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(min-width: 1280px)")
            .addEventListener("change", (e) => setDesktopRes(e.matches));
    }, []);

    useEffect(() => {
        if (started === true) {
            localStorage.setItem(`${clientId}_s`, "true");
        }
    }, [clientId, started]);

    useEffect(() => {
        const localedStarted = localStorage.getItem(`${clientId}_s`);
        if (localedStarted === "true") {
            setStarted(true);
        }
    }, [clientId]);

    const handleChatButtonClick = (message: string) => {
        setInitialMessage(message);
        setIsChatOpen(true);
    };

    return (
        <div className="h-screen flex items-center justify-center xl:justify-end">
            <BackgroundImage
                image={images[activeItem]}
                isChatOpen={isChatOpen && !desktopRes}
            />
            {desktopRes && (
                <InitialButtons
                    isChatOpen={isChatOpen}
                    started={started}
                    onButtonClick={handleChatButtonClick}
                    desktopRes={desktopRes}
                />
            )}
            <Feedback
                clientId={clientId}
                isOpen={isFeedbackOpen}
                setIsOpen={setIsFeedbackOpen}
            />
            <div className={`${desktopRes ? "" : "z-10"}`}>
                {!desktopRes && (
                    <InitialButtons
                        isChatOpen={isChatOpen}
                        started={started}
                        onButtonClick={handleChatButtonClick}
                        desktopRes={desktopRes}
                    />
                )}
                <ChatWindow
                    isOpen={isChatOpen}
                    desktopRes={desktopRes}
                    onClose={() => setIsChatOpen(false)}
                    initialMessage={initialMessage}
                    setInitialMessage={setInitialMessage}
                    clientId={clientId}
                    streaming={streaming}
                    setStreaming={(value: boolean) => setStreaming(value)}
                    setHotelsReady={(value: boolean) => setHotelsReady(value)}
                    hotelsReady={hotelsReady}
                    started={started}
                    setStarted={() => setStarted(true)}
                    shouldFocus={!isFeedbackOpen}
                    openFeedback={() => setIsFeedbackOpen(true)}
                />
            </div>
        </div>
    );
};

export default App;
