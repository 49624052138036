import React, { useState, useRef, useEffect } from "react";
import { useChatCompletion } from "../../hooks/useChatCompletion";
import ChatExpandable from "./ChatExpandable";
import { Message, MessageReaction } from "../../models/ChatModel";
import { SessionReactionsModel } from "../../models/FeedbackModel";
import InputInChat from "./InputInChat";
import ChatContent from "./ChatContent";
import { getUrl } from "../../utils/utils";
import "../../styles/Chat.css"

interface ChatWindowProps {
    isOpen: boolean;
    onClose: () => void;
    initialMessage?: string;
    setInitialMessage: (value: string) => void;
    clientId: string;
    streaming: boolean;
    setStreaming: (value: boolean) => void;
    hotelsReady: boolean;
    setHotelsReady: (value: boolean) => void;
    setStarted: () => void;
    started: boolean;
    desktopRes: boolean;
    shouldFocus: boolean;
    feedbackPage?: React.ReactNode;
    openFeedback: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
    isOpen,
    onClose,
    initialMessage,
    setInitialMessage,
    clientId,
    streaming,
    setStreaming,
    setStarted,
    started,
    desktopRes,
    shouldFocus,
    openFeedback,
}) => {
    const [input, setInput] = useState("");
    const [lastMessage, setLastMessage] = useState<Message>();
    const { messages, loading, submitPrompt, setMessages } = useChatCompletion(
        clientId,
        new URL(`${getUrl()}/api/v1/stream`)
    );
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && initialMessage && !streaming) {
            setInput(initialMessage);
            setInitialMessage("");
        }
    }, [
        isOpen,
        initialMessage,
        submitPrompt,
        streaming,
        setStreaming,
        setInitialMessage,
    ]);

    useEffect(() => {
        if (messages.length > 3 && !started) {
            setStarted();
        }
    }, [messages.length, setStarted, started]);

    useEffect(() => {
        if (messages[messages.length - 1] !== lastMessage) {
            setLastMessage(messages[messages.length - 1]);
        }
    }, [lastMessage, messages]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView(true)
    }, [lastMessage, isOpen]);

    const handleSubmit = (e?: React.FormEvent) => {
        e?.preventDefault();
        setInitialMessage("");
        if (input.trim()) {
            submitPrompt({ role: "user", content: input });
            setInput("");
        }
    };

    const handleFeedback = (reaction: MessageReaction, message: Message) => {
        const updatedMessages = messages.slice();
        const index = updatedMessages.findIndex((m) => m === message);

        if (updatedMessages[index].feedback === reaction) {
            updatedMessages[index].feedback = undefined;
        } else {
            updatedMessages[index].feedback = reaction;
        }

        setMessages(updatedMessages);

        const feedbackDataToSubmit = {
            sessionId: clientId,
            messages: [
                {
                    messageId: message.id,
                    reaction: reaction,
                },
            ],
        } as SessionReactionsModel;

        fetch(new URL(`${getUrl()}/api/v1/messageReactions`), {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(feedbackDataToSubmit),
        })
            .then((res) => console.log(`Feedback sent: ${res}`))
            .catch((err) => {
                console.error(err.message);
            });
    };

    const cantSend = (): boolean => loading || input.length < 1;

    return (
        <>
            {!desktopRes && (
                <ChatExpandable
                    isOpen={isOpen}
                    onClose={onClose}
                    input={
                        <InputInChat
                            handleSubmit={handleSubmit}
                            input={input}
                            setInput={setInput}
                            cantSend={cantSend}
                            desktopRes={desktopRes}
                            shouldFocus={shouldFocus}
                        />
                    }
                    feedbackOpened={shouldFocus}
                    openFeedback={openFeedback}
                >
                    <ChatContent
                        messagesEndRef={messagesEndRef}
                        messages={messages}
                        handleFeedback={handleFeedback}
                        clientId={clientId}
                        loading={loading}
                        desktopRes={desktopRes}
                    />
                </ChatExpandable>
            )}
            {desktopRes && (
                <div className="xl:w-[32dvw] xl:h-screen xl:relative xl:right-0 xl:px-8 gradient-background xl:flex xl:flex-col xl:pb-6">
                    <ChatContent
                        messagesEndRef={messagesEndRef}
                        messages={messages}
                        handleFeedback={handleFeedback}
                        clientId={clientId}
                        loading={loading}
                        desktopRes={desktopRes}
                    />
                    <InputInChat
                        handleSubmit={handleSubmit}
                        input={input}
                        setInput={setInput}
                        cantSend={cantSend}
                        desktopRes={desktopRes}
                        shouldFocus={shouldFocus}
                    />
                </div>
            )}
        </>
    );
};

export default ChatWindow;
