import { useState, useEffect, useCallback } from "react";
import { GalleryImage } from "../models/GalleryModel";
import BgInitial from "../images/i.webp";
import { getUrl } from "../utils/utils";

export const useImageGallery = (clientId: string, streaming: boolean) => {
    const [images, setImages] = useState<GalleryImage[]>([]);
    const [activeItem, setActiveItem] = useState(0);

    const fetchData = useCallback(
        (endpoint: string) => {
            fetch(
                new URL(
                    `${getUrl()}/` +
                        `api/v1/${endpoint}?` +
                        new URLSearchParams({
                            clientId: clientId,
                        })
                )
            )
                .then((res) => res.json())
                .then((data) => {
                    if (
                        data !== undefined &&
                        data != null &&
                        (data as []).length > 0
                    ) {
                        console.log("New images found:");
                        console.log(data);
                        const imgs = data as GalleryImage[];

                        if (endpoint === "initImages") {
                            imgs.unshift({
                                imageUrl: BgInitial,
                            } as GalleryImage);
                            setImages(imgs);
                        } else {
                            const imageResults = processImages(
                                data as GalleryImage[]
                            );
                            setImages(imageResults);

                            if (imageResults.length > 1) {
                                localStorage.setItem(
                                    `${clientId}_p`,
                                    JSON.stringify(imageResults)
                                );
                            }
                        }

                        setActiveItem(0);
                    }
                })
                .catch((err) => {
                    console.error(err.message);
                });
        },
        [clientId]
    );

    // try init images
    useEffect(() => {
        const savedImages = localStorage.getItem(`${clientId}_p`);

        try {
            if (savedImages) {
                setImages(JSON.parse(savedImages));
            }
        } catch (err) {
            console.log(err);
        }

        if (savedImages?.length == null) {
            setImages([
                {
                    imageUrl: BgInitial,
                } as GalleryImage,
            ]);
        }

        const intervalId = setInterval(() => fetchData("images"), 5000);

        return () => clearInterval(intervalId);
    }, [clientId, fetchData]);

    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setImages((currentItems) => {
                    const nextIndex = (activeItem + 1) % currentItems.length;
                    setActiveItem(nextIndex);
                    return currentItems.map((item, index) => ({
                        ...item,
                        active: index === nextIndex,
                    }));
                });
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [activeItem, images.length]);

    return { images, activeItem, setActiveItem };
};

function processImages(images: GalleryImage[]): GalleryImage[] {
    const imageMap = new Map<string, GalleryImage>();

    images.forEach((item) => {
        const fileName = item.imageUrl?.split("/").pop()!;
        const baseFileName = fileName.replace(/__small_64_48/, "");

        if (!imageMap.has(baseFileName)) {
            imageMap.set(baseFileName, {
                imageUrl: "",
                placeholderUrl: "",
            });
        }

        const resultItem = imageMap.get(baseFileName)!;

        if (item.placeholderUrl === "original") {
            resultItem.imageUrl = item.imageUrl;
        } else {
            resultItem.placeholderUrl = item.imageUrl;
        }
    });

    return Array.from(imageMap.values()).filter(
        (item) => item.imageUrl && item.placeholderUrl
    );
}
