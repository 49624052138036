import React, { useEffect, useState } from "react";
import { GalleryImage } from "../../models/GalleryModel";

interface BackgroundImageProps {
    image: GalleryImage;
    isChatOpen: boolean;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
    image,
    isChatOpen,
}) => {
    const [currentImage, setCurrentImage] = useState<GalleryImage | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (
            image !== currentImage ||
            image.imageUrl !== currentImage.imageUrl
        ) {
            setImageLoaded(false);

            const img = new Image();
            img.src = image?.imageUrl ?? "";
            img.onload = () => {
                setImageLoaded(true);

                const timer = setTimeout(() => {
                    setCurrentImage(image);
                }, 1000); // Время перехода в миллисекундах

                return () => clearTimeout(timer);
            };
        }
    }, [image, currentImage]);

    const getBackgroundImage = (img: GalleryImage | null) => {
        if (!img) return "none";
        return `url(${imageLoaded ? img?.imageUrl : currentImage?.imageUrl})`;
    };

    return (
        <div
            className={`fixed inset-0 z-0 bg-cover bg-center transition-all duration-1000 ease-in-out ${
                isChatOpen ? "h-[45dvh]" : "h-screen"
            } xl:w-[69dvw] xl:rounded-r-3xl xl:z-[11]`}
            style={{
                backgroundImage: getBackgroundImage(currentImage),
            }}
        />
    );
};

export default BackgroundImage;
