const str = () =>
    (
        "00000000000000000" + (Math.random() * 0xffffffffffffffff).toString(16)
    ).slice(-16);

export const uuid = () => {
    const a = str();
    const b = str();
    return (
        a.slice(0, 8) +
        "-" +
        a.slice(8, 12) +
        "-4" +
        a.slice(13) +
        "-a" +
        b.slice(1, 4) +
        "-" +
        b.slice(4)
    );
};

export const validateUuid = (uuid: string): boolean => {
    return new RegExp(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    ).test(uuid);
};

export const formatPriceNumber = (number?: number | string) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export function formatDate(
    date?: string | number
): string | number | undefined {
    if (date && typeof date === "string") {
        const parts = date.split("-");
        if (parts.length === 3) {
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const day = parseInt(parts[2], 10);
            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                const newDate = new Date(year, month - 1, day);
                if (
                    newDate.getFullYear() === year &&
                    newDate.getMonth() === month - 1 &&
                    newDate.getDate() === day
                ) {
                    return `${String(day).padStart(2, "0")}.${String(
                        month
                    ).padStart(2, "0")}.${year}`;
                }
            }
        }
        return date;
    }
}

export const getChildrensText = (children?: string | number): string => {
    try {
        if (!children) return " без детей";
        if (typeof children === "number") {
            if (children === 0) return " без детей";
            else if (children === 1) return " 1 ребенок";
            else if (children > 1 && children < 5)
                return ` ${children} ребенка`;
            else return ` ${children} детей`;
        } else {
            return getChildrensText(Number(children));
        }
    } catch (e) {
        console.log(e);
        return " без детей";
    }
};

export function getCookie(name: string) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

export function capitalizeFirstLetter(data?: string): string | undefined {
    if (data) {
        return data.charAt(0).toUpperCase() + data.slice(1);
    }
}

export const getUrl = () => {
    const url = (window as any).env?.REACT_APP_BACKEND_URL;
    if (url) {
        return url;
    }
    throw new Error(
        "К сожалению, произошла непредвиденная ошибка. Пожалуйста, попробуйте ещё раз или повторите попытку позже."
    );
};
