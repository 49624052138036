import {
    Root,
    Portal,
    Trigger,
    Content,
    Description,
    Title,
    Close,
    Overlay,
} from "@radix-ui/react-dialog";
import { Cross1Icon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import StarRating from "./StarRating";
import { FeedbackDataModel } from "../../models/FeedbackModel";
import { getUrl } from "../../utils/utils";
import * as Toast from "@radix-ui/react-toast";

const notificationText = [
    "Спасибо за вашу обратную связь! Мы ценим ваше мнение и учтем его в нашей работе.",
    "К сожалению, что-то пошло не так. Попробуйте, пожалуйста, позже.",
];

interface FeedbackProps {
    clientId: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const Feedback: React.FC<FeedbackProps> = ({ clientId, isOpen, setIsOpen }) => {
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [pressed, setPressed] = useState(false);
    const [notification, setNotification] = useState(false);
    const timerRef = React.useRef(0);
    const [notifText, setNotifText] = useState(0);

    React.useEffect(() => {
        const currentTime = timerRef.current;
        return () => clearTimeout(currentTime);
    }, []);

    const handleSubmit = () => {
        if (cantSend()) {
            return;
        }
        setPressed(true);

        console.log({ clientId, rating, feedback });
        try {
            const feedbackDataToSubmit = {
                sessionId: clientId,
                overallFeedback: rating !== 0 ? rating : null,
                text: feedback !== "" ? feedback : null,
            } as FeedbackDataModel;

            fetch(new URL(`${getUrl()}/api/v1/feedback`), {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(feedbackDataToSubmit),
            })
                .then((res) => {
                    console.log(`Feedback sent: ${res}`);
                    setNotifText(0);
                    setIsOpen(false);
                    setRating(0);
                    setFeedback("");
                })
                .catch((err) => {
                    setNotifText(1);
                    console.error(err);
                });
        } catch (err) {
            setNotifText(1);
            console.error(err);
        } finally {
            setPressed(false);
            setNotification(true);
        }
    };

    const cantSend = () => rating === 0 && feedback === "";

    return (
        <Toast.Provider swipeDirection="right">
            <Root open={isOpen} onOpenChange={setIsOpen}>
                <Trigger
                    asChild
                    className={`fixed size-8 top-0 right-0 m-3 bg-[rgba(23,23,23,0.6)] xl:right-[32dvw] xl:top-[calc(100dvh-60px)] rounded-full z-20 pointer-events-auto`}
                >
                    <button>
                        <QuestionMarkCircledIcon className="mx-auto size-5 opacity-100 text-white" />
                    </button>
                </Trigger>
                <Portal>
                    <Overlay className="xl:bg-black/50 xl:z-30 xl:w-screen xl:h-screen xl:fixed xl:top-0 xl:left-0" />
                    <Content className="transition-all fixed inset-0 bg-white z-50 w-screen h-screen flex flex-col p-4 xl:py-8 xl:px-10 feedback-content xl:w-[30dvw] xl:h-[30dvh] xl:mx-auto xl:my-auto xl:min-h-80 xl:min-w-[540px] xl:rounded-3xl">
                        <div className="flex justify-between items-center pb-4 xl:pb-0 mb-6 xl:mb-4 border border-transparent border-b-neutral-200 xl:border-b-0">
                            <Title className="text-xl font-extrabold noselect">
                                Оставьте отзыв
                            </Title>
                            <Close asChild>
                                <button className="rounded-full size-5 bg-black hover:bg-gray-800 transition-colors box-border">
                                    <Cross1Icon className="size-2.5 text-white mx-auto box-border" />
                                </button>
                            </Close>
                        </div>
                        <div className="space-y-4 xl:space-y-0">
                            <div className="py-2 xl:py-0">
                                <Description className="text-sm xl:font-medium mb-2 text-center font-extrabold xl:text-left noselect">
                                    Оцените помощника
                                </Description>
                                <StarRating
                                    rating={rating}
                                    setRating={setRating}
                                />
                            </div>
                            <div>
                                <textarea
                                    id="feedback"
                                    value={feedback}
                                    onChange={(e) =>
                                        setFeedback(e.target.value)
                                    }
                                    className="w-full h-32 mt-1 p-3 border border-gray-300 rounded-xl resize-none font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 xl:h-20"
                                    placeholder="Общее впечатление"
                                />
                            </div>
                        </div>
                        <div className="mt-auto border border-transparent border-t-neutral-200 xl:border-t-0 pt-4 flex flex-row-reverse xl:flex-row">
                            <button
                                onClick={handleSubmit}
                                className={`bg-orange-500 xl:bg-[#FFB534] text-white xl:text-black py-2 px-4 rounded-md xl:rounded-xl hover:xl:bg-[#FFB534] hover:bg-orange-600 transition-colors noselect ${
                                    pressed ? " animate-pulse cursor-wait " : ""
                                } ${cantSend() ? " cursor-not-allowed " : ""}`}
                            >
                                Отправить
                            </button>
                        </div>
                    </Content>
                </Portal>
            </Root>

            <Toast.Root
                className="bg-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
                open={notification}
                onOpenChange={setNotification}
            >
                <Toast.Title className="[grid-area:_title] mb-[5px] font-medium text-slate12 text-[15px]">
                    {notificationText[notifText]}
                </Toast.Title>
                <Toast.Description asChild />
                <Toast.Action
                    className="[grid-area:_action]"
                    asChild
                    altText="close"
                >
                    <Cross1Icon />
                </Toast.Action>
            </Toast.Root>
            <Toast.Viewport className="[--viewport-padding:_25px] fixed top-10 right-0 xl:left-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
        </Toast.Provider>
    );
};

export default Feedback;
