import React, { useState } from "react";

interface HotelLinkProps {
    clientId: string;
    tourId?: string;
    url: string;
    backLink?: string;
    customText?: string;
}

const HotelLink: React.FC<HotelLinkProps> = ({
    clientId,
    tourId,
    url,
    backLink,
    customText,
}) => {
    const [pressed, setPressed] = useState(false);
    const handleButtonClick = async () => {
        setPressed(true);
         // fix open link in safari
        const windowReference = window.open();
        try {
            // Make a request to generate the new link
            fetch(
                new URL(
                    url +
                        "api/v1/offer-link?" +
                        new URLSearchParams({
                            clientId: clientId,
                            offerId: tourId ?? "",
                        })
                )
            )
                .then((data) => data.text())
                .then(function (url) {
                    if (windowReference) {
                        windowReference.location = url;
                    }
                })
                .catch((e) => {
                    console.error("Error:", e);
                    if (windowReference) {
                        windowReference.location =
                            backLink ?? "https://www.onlinetours.ru";
                    }
                });
        } catch (error) {
            if (windowReference) {
                windowReference.location =
                    backLink ?? "https://www.onlinetours.ru";
            }
            console.error("Error:", error);
        } finally {
            setPressed(false);
        }
    };

    return (
        <div
            className={`mt-2 bg-yellow-400 xl:bg-[#FFB534] text-black py-2 px-4 rounded-xl w-full cursor-pointer ${
                pressed ? "animate-pulse cursor-wait" : ""
            }`}
        >
            <div
                className="text-center font-semibold xl:font-medium xl:text-xs xl:leading-none 2xl:text-base"
                onClick={handleButtonClick}
            >
                {customText ?? "Посмотреть варианты"}
            </div>
        </div>
    );
};

export default HotelLink;
