import React, { memo, useEffect, useRef, useState } from "react";
import Markdown, { Components } from "react-markdown";
import { visit } from "unist-util-visit";
import { Node, Parent } from "unist";
import { formatDate } from "../../utils/utils";

const animateDelay = (delay: number): React.CSSProperties => ({
    animationDelay: `${delay * 100}ms`,
});

interface MarkdownTextProps {
    children: string;
    className?: string;
}

interface CustomNode extends Node {
    tagName?: string;
    value?: string;
    children?: CustomNode[];
    properties?: Record<string, unknown>;
}

const MarkdownText: React.FC<MarkdownTextProps> = memo(({ children }) => {
    const [key, setKey] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);
    let next = 0;

    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [children]);

    useEffect(() => {
        if (divRef.current) {
            const list = divRef.current.querySelectorAll(".animate__animated");
            if (list.length) {
                list[list.length - 1].addEventListener(
                    "animationend",
                    (e) => {
                        e.stopPropagation();
                    },
                    { once: true }
                );
            }
        }
    }, [divRef, key]);

    const rehypeWrapText = () => (tree: Node) => {
        visit(
            tree,
            "text",
            (node: CustomNode, index: number, parent: Parent | undefined) => {
                if (
                    parent &&
                    parent.type === "element" &&
                    (parent as CustomNode).tagName !== "text"
                ) {
                    const newNode: CustomNode = {
                        type: "element",
                        tagName: "text",
                        children: [{ type: "text", value: node.value }],
                        properties: {},
                    };
                    parent.children[index] = newNode as Node;
                }
            }
        );
    };

    const components: Partial<Components> = {
        text: ({ children, ...props }) => {
            if (children == null) {
                return null;
            }
            if (typeof children !== "string") {
                return <>{children}</>;
            }
            // Проверяем, находится ли текст внутри блока кода
            const isInsideCodeBlock =
                props.node &&
                props.node.position &&
                props.node.position.start.line === props.node.position.end.line;
            if (isInsideCodeBlock) {
                return <>{children}</>;
            }
            return (
                <>
                    {children
                        .split(" ")
                        .map((s) => s.trim())
                        .filter((s) => !!s)
                        .map((span, i) => (
                            <span
                                key={i}
                                className="animate__animated animate__fadeIn animate__delay"
                                style={animateDelay(++next)}
                            >
                                {formatDate(span)}{" "}
                            </span>
                        ))}
                </>
            );
        },
        ul: ({ children, ...props }) => (<ul className="animate__animated animate__fadeIn animate__delay ul" style={animateDelay(++next)}>{children}</ul>),
    };

    return (
        <div ref={divRef} key={key}>
            <Markdown
                className={"assistant xl:text-[rgba(82,82,82)]"}
                rehypePlugins={[rehypeWrapText]}
                components={components}
            >
                {children}
            </Markdown>
        </div>
    );
});

export default MarkdownText;
